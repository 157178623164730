import { useEffect } from 'react';

import type { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { Home } from '@templates/Home/Home';

import { isAppApproaching } from '@utils/Common/is-app-approaching';

import type { UserInfo } from './personal/funnel';

const Footer = dynamic(() => import('@components/Footer').then((v) => ({ default: v.Footer })));

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
  const userAgent = req.headers['user-agent'];
  const isAppUser = userAgent?.includes('WISHROOM');

  if (!isAppUser) {
    return {
      props: {},
    };
  }

  const getUserInfoFromCookies = (cookies: string | undefined): UserInfo | null => {
    if (!cookies) return null;

    const userInfoCookie = cookies
      .split('; ')
      .find((cookie) => cookie.startsWith('user-info='))
      ?.split('=')[1];

    if (userInfoCookie) {
      try {
        return JSON.parse(decodeURIComponent(userInfoCookie));
      } catch (error) {
        console.error('Error parsing user-info cookie:', error);
      }
    }

    return null;
  };

  const parsedUserInfo = getUserInfoFromCookies(req.headers.cookie);

  if (parsedUserInfo) {
    return {
      props: {
        userInfo: parsedUserInfo,
      },
    };
  }

  return {
    redirect: {
      destination: '/personal/funnel',
      permanent: false,
    },
    props: { userInfo: null },
  };
};

const isApp = isAppApproaching();

export default function HomePage() {
  const router = useRouter();

  useEffect(() => {
    if (!isApp) {
      return;
    }

    const userInfo = localStorage.getItem('user-info');

    if (!userInfo) {
      router.push('/personal/funnel');
    }
  }, [router]);

  return (
    <>
      <Home />
      <Footer />
    </>
  );
}
